define("portal-talentrh/pods/have-docs-to-sign/show-receipt/controller", ["exports", "moment", "ember-concurrency-decorators", "portal-talentrh/config/environment"], function (_exports, _moment, _emberConcurrencyDecorators, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let HaveDocsToSignSignShowReceiptController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.computed.alias('permission.docToSign'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, (_class = class HaveDocsToSignSignShowReceiptController extends Ember.Controller {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
      _initializerDefineProperty(this, "permission", _descriptor2, this);
      _initializerDefineProperty(this, "fetch", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _initializerDefineProperty(this, "toast", _descriptor5, this);
      _initializerDefineProperty(this, "router", _descriptor6, this);
      _initializerDefineProperty(this, "docToSign", _descriptor7, this);
      _initializerDefineProperty(this, "selected", _descriptor8, this);
      _initializerDefineProperty(this, "branchSelected", _descriptor9, this);
      _initializerDefineProperty(this, "receipt", _descriptor10, this);
    }
    buildFilters() {
      let printReceipt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.selected = {
        competence: this.docToSign.competence,
        data: this.docToSign.data
      };
      let filter = {};
      let date = (0, _moment.default)(this.docToSign.data).format('DD/MM/YYYY');
      let processId = this.docToSign.idProcesso;
      let emission = (0, _moment.default)().format('DD/MM/YYYY HH:mm');
      filter.repeat = 'false';
      filter.date = date;
      filter.process = processId;
      filter.emission = emission;
      filter.printReceipt = printReceipt;
      filter.folha = this.docToSign.folha;
      return filter;
    }
    *findReceipt(pdf) {
      let swalLoading = null;
      try {
        if (this.model.user.branches.length <= 1) {
          this.branchSelected = this.model.user.branches.content.currentState[0];
        }
        const headers = {};
        const authToken = this.session.token;
        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        }
        const filter = this.buildFilters(pdf);
        let url = `${_environment.default.apiUrl.social}/receipts/report`;
        const search = new URLSearchParams(filter).toString();
        url += `?${search}`;
        if (pdf) {
          swalLoading = this.swal.loading('Gerando o pdf...');
        }
        const response = yield fetch(url, {
          method: 'GET',
          headers
        });
        if (pdf) {
          const report = yield response.blob();
          const objectURL = URL.createObjectURL(report);
          const link = document.createElement('a');
          link.href = objectURL;
          const documentType = this.docToSign.typeDocument === 'timecard' ? 'cartaoPonto' : 'reciboPagamento';
          const formattedCompetence = this.docToSign.competence.replace('/', '').replace(/\s+/g, '');
          link.download = `${this.permission.user.cpf}_${documentType}_${formattedCompetence}.pdf`.trim();
          document.body.append(link);
          link.click();
          link.remove();
          swalLoading.close();
        } else {
          this.receipt = yield response.json();
        }
      } catch (e) {
        if (swalLoading) swalLoading.close();
        this.swal.catch(e);
      }
    }
    *sign() {
      const document = this.docToSign;
      try {
        const signedDocument = yield this.fetch.request('signDocument', {
          method: 'POST',
          data: {
            document
          }
        });
        const updatedDocuments = this.permission.get('docsToSign').filter(doc => doc.id !== signedDocument.id);
        this.permission.set('docsToSign', updatedDocuments);
        this.toast.success('Assinatura eviada!');
        this.router.transitionTo('feed');
      } catch (e) {
        console.log('error', e);
        return this.swal.catch(e);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "permission", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fetch", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "docToSign", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "branchSelected", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "receipt", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "buildFilters", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "buildFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findReceipt", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "findReceipt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sign", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "sign"), _class.prototype)), _class));
  _exports.default = HaveDocsToSignSignShowReceiptController;
});