define("portal-talentrh/pods/have-docs-to-sign/show-time-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lmLrS/b7",
    "block": "{\"symbols\":[\"Card\",\"competence\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-4\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"font-weight-bold text-secondary\"],[4,[38,1],[[30,[36,0],[[32,0,[\"getReport\"]],false],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Cartão Ponto\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row align-items-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[12],[2,\"Competência\"],[13],[2,\"\\n\\n          \"],[8,\"power-select\",[],[[\"@placeholder\",\"@selected\",\"@renderInPlace\",\"@options\",\"@onChange\",\"@disabled\",\"@noMatchesMessage\"],[\"Selecionar competência\",[32,0,[\"competence\"]],true,[32,0,[\"competences\"]],[30,[36,3],[[30,[36,2],[[32,0,[\"competence\"]]],null]],null],true,\"Nenhum registro encontrado\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2]],[2,\"\\n          \"]],\"parameters\":[2]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col text-right\"],[12],[2,\"\\n        \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"sign\"]]],null]],[14,0,\"btn btn-primary btn-sm mt-2\"],[12],[2,\"\\n          Assinar documento\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\\n\"],[6,[37,4],[[32,0,[\"timeCard\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"time-card-report\",[],[[\"@timeCard\",\"@downloadPdf\",\"@fromMyself\"],[[32,0,[\"timeCard\"]],[30,[36,0],[[32,0,[\"getReport\"]],true],null],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"mut\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/have-docs-to-sign/show-time-card/template.hbs"
    }
  });
  _exports.default = _default;
});