define("portal-talentrh/pods/have-docs-to-sign/show-receipt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yvHLA+AV",
    "block": "{\"symbols\":[\"Card\",\"folha\"],\"statements\":[[8,\"ui-card\",[[24,0,\"shadow-sm mb-3\"],[4,[38,1],[[30,[36,0],[[32,0,[\"findReceipt\"]],false],null]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"Header\"]],[[24,0,\"font-weight-bold\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    Gerar recibo de pagamento\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1,[\"Body\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"row align-items-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-8\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n          \"],[10,\"label\"],[12],[2,\"Recibo de pagamento\"],[13],[2,\"\\n          \"],[8,\"nela-select\",[],[[\"@searchEnabled\",\"@options\",\"@selected\",\"@disabled\",\"@placeholder\"],[false,[32,0,[\"docToSign\"]],[32,0,[\"selected\"]],true,\"Selecione um recibo de pagamento\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"competence\"]]],[2,\" - \"],[1,[30,[36,2],[[32,2,[\"data\"]],\"DD/MM/YYYY\"],null]],[2,\"\\n          \"]],\"parameters\":[2]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-4 text-right\"],[12],[2,\"\\n        \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0,[\"sign\"]]],null]],[14,0,\"btn btn-primary btn-sm mt-2 mr-20\"],[12],[2,\"\\n          Assinar documento\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"receipt\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"payment-receipt\",[],[[\"@receipt\",\"@downloadPdf\"],[[32,0,[\"receipt\",\"model\"]],[30,[36,0],[[32,0,[\"findReceipt\"]],true],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"perform\",\"did-insert\",\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "portal-talentrh/pods/have-docs-to-sign/show-receipt/template.hbs"
    }
  });
  _exports.default = _default;
});