define("portal-talentrh/pods/components/post-item/component", ["exports", "ember-concurrency", "moment", "xss", "text-clipper"], function (_exports, _emberConcurrency, _moment, _xss, _textClipper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ICONS = {
    ad: 'bullhorn climate-color',
    announcement: 'file-text nela-color',
    event: 'ticket training-color',
    link: 'link',
    message: 'envelope schedule-color',
    news: 'newspaper accounts-color',
    survey: 'file-alt schedule-color'
  };
  const POST_TYPES = {
    ad: 'um classificado',
    announcement: 'um comunicado',
    event: 'um evento',
    message: 'um post',
    news: 'uma notícia',
    survey: 'uma enquete'
  };
  const POST_TEXT_MAX_SIZE = 350;
  var _default = Ember.Component.extend({
    // HTML attributes
    tagName: 'li',
    classNames: ['post-item'],
    // Services
    ajax: Ember.inject.service(),
    modal: Ember.inject.service(),
    permission: Ember.inject.service(),
    screen: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    swal: Ember.inject.service(),
    toast: Ember.inject.service(),
    // Default values
    commentIsFocused: false,
    editingId: 0,
    showMorePostText: false,
    showMoreIsNeeded: false,
    branches: Ember.A(),
    showBranchesViewModal: false,
    // Single Line Computed Properties
    configuration: Ember.computed.alias('permission.companyConfig'),
    accessProfile: Ember.computed.alias('permission.accessProfile'),
    postBranches: Ember.computed.alias('post.branches'),
    images: Ember.computed.filterBy('post.images', 'summernote', false),
    videos: Ember.computed.filterBy('post.videos', 'summerNote', false),
    usersFavorited: Ember.computed.mapBy('post.usersFavorited', 'id'),
    companyConfig: Ember.computed.alias('permission.companyConfig'),
    // Multiline Computed Properties
    computedPostIcon: Ember.computed('post.type', function () {
      return `fa fa-${ICONS[this.post.type || 'message']}`;
    }),
    canEditPost: Ember.computed('permission.admin', 'post.{nelaContent,user.id}', 'session.user.id', function () {
      let userId = parseInt(Ember.get(this, 'post.user.id'));
      return userId === this.session.user.id || this.permission.admin;
    }),
    canEditPostVacancy: Ember.computed('permission.{accessProfile.allowManageVacancies,admin}', 'post.{nelaContent,type,user.id}', 'session.user.{admin,id}', function () {
      let userId = parseInt(Ember.get(this, 'post.user.id'));
      let type = Ember.get(this, 'post.type');
      if (type === 'vacancy') {
        return userId === this.session.user.id || this.permission.admin || this.permission.accessProfile.allowManageVacancies;
      }
      return;
    }),
    postFormattedCreatedAt: Ember.computed('post.createdAt', function () {
      const currentDate = (0, _moment.default)();
      const isOverAWeekAgo = (0, _moment.default)(this.post.createdAt).isBefore(currentDate.subtract(1, 'weeks'));
      if (isOverAWeekAgo) {
        return (0, _moment.default)(this.post.createdAt).format('DD/MM/YYYY HH:mm');
      }
      return (0, _moment.default)(this.post.createdAt).fromNow();
    }),
    isConfirmed: Ember.computed('post.confirmedPresences.length', 'session.user.id', function () {
      return this.post.confirmedPresences.find(u => parseInt(u.id) === this.session.user.id);
    }),
    isFavorited: Ember.computed('session.user.id', 'usersFavorited.[]', function () {
      let user = String(this.session.user.id);
      return this.usersFavorited.includes(user);
    }),
    isLiked: Ember.computed('post.usersLiked.length', 'session.user.id', function () {
      return this.post.usersLiked.includes(this.session.user.id);
    }),
    link: Ember.computed('post.link', function () {
      let urlRegex = /(https?:\/\/[^\s]+)/g;
      let postLink = this.post.link;
      if (!urlRegex.test(postLink)) {
        return `http://${postLink}`;
      }
      return postLink;
    }),
    postBody: Ember.computed('post.body', 'showMorePostText', function () {
      const postBodyWithoutTags = this.getPostBodyWithoutTags(this.post);
      if (postBodyWithoutTags.length > POST_TEXT_MAX_SIZE && !this.showMorePostText) {
        const clippedHtml = (0, _textClipper.default)(this.post.body, POST_TEXT_MAX_SIZE, {
          html: true
        });
        return clippedHtml;
      }
      const bodyPost = this.post.body ? this.post.body.replace(/<video src=/g, "<video controlslist='nodownload' src=") : this.post.body;
      return bodyPost || '';
    }),
    postedInGroup: Ember.computed('post.userGroup.{id,name}', function () {
      return Ember.get(this, 'post.userGroup.id') && Ember.get(this, 'post.userGroup.name') !== 'ALL';
    }),
    publishOnlyInGroup: Ember.computed('permission', function () {
      return this.permission.accessProfile.publishOnlyInGroup && !this.permission.admin;
    }),
    postedInBranches: Ember.computed('post.branches', function () {
      return Ember.get(this, 'post.branches').length;
    }),
    postImages: Ember.computed('images.[]', function () {
      if (!this.images) {
        return Ember.A();
      }
      const maxImages = 4;
      if (this.images.length <= maxImages) {
        return this.images;
      }
      let result = this.images.slice(0, 3);
      result.pushObject({
        count: this.images.length - 4,
        img: this.images.objectAt(3),
        imgs: this.images.slice(4, this.images.length)
      });
      return result;
    }),
    postTypeText: Ember.computed('companyConfig.featureFlags.{ENABLE,postPerBranch}', 'post.branches.firstObject.{name,tradingName}', 'post.branches.length', 'post.type', function () {
      const typeText = POST_TYPES[this.post.type];
      if (this.post.branches && this.post.branches.length && this.companyConfig.featureFlags.ENABLE && this.companyConfig.featureFlags.postPerBranch) {
        const firstBranchName = this.post.branches.firstObject.tradingName || this.post.branches.firstObject.name;
        let branchText = '';
        if (firstBranchName) {
          branchText += ` na filial ${firstBranchName}`;
          if (this.post.branches.length > 1) {
            branchText += ` e em mais ${this.post.branches.length - 1} filial(is)`;
          }
        }
        return `${typeText} ${branchText}`;
      }
      return typeText;
    }),
    showOptions: Ember.computed('configuration.permitComplaint', 'permission.admin', 'post.type', 'post.user.id', 'session.user.{admin,id}', function () {
      let userPost = Ember.get(this, 'post.user.id');
      let type = Ember.get(this, 'post.type');
      let admin = this.permission.admin;
      let permitComplaint = Ember.get(this, 'configuration.permitComplaint') && 'defaultPost' !== type;
      let isOwner = parseInt(userPost) === this.session.user.id;
      return isOwner || admin || permitComplaint;
    }),
    showComplaint: Ember.computed('configuration.permitComplaint', 'permission.admin', 'post.{nelaContent,type}', function () {
      let type = this.post.type;
      let permitComplaint = this.configuration.permitComplaint;
      let defaultPost = 'defaultPost' !== type;
      if (this.post.nelaContent) {
        return false;
      }
      return defaultPost && permitComplaint || this.permission.admin && permitComplaint;
    }),
    canShareBranchs: Ember.computed('accessProfile.{allowPublishPerBranch,publishOnlyInGroup,admin}', 'companyConfig.featureFlags.{ENABLE,postPerBranch}', function () {
      return (this.accessProfile.allowPublishPerBranch || this.accessProfile.admin) && !this.accessProfile.publishOnlyInGroup && this.companyConfig.featureFlags.ENABLE && this.companyConfig.featureFlags.postPerBranch;
    }),
    canShareOnlyGroup: Ember.computed('accessProfile.{publishOnlyInGroup,admin}', function () {
      return this.accessProfile.publishOnlyInGroup && !this.accessProfile.admin;
    }),
    canShareGroup: Ember.computed('configuration.permitGroups', 'post.userGroup.public', 'userGroup.public', function () {
      return this.configuration.permitGroups && (!this.userGroup || this.userGroup.public);
    }),
    canSharePosts: Ember.computed('accessProfile.{admin,allowSharePosts,postAd,postAnnouncement,postMessage,postNews}', 'post.type', function () {
      const type = this.post.type;
      const allowedType = type !== 'event' && type !== 'survey' && type !== 'admissionAnniversary' && type !== 'birthday';
      let hasTypePermission;
      switch (type) {
        case 'message':
          hasTypePermission = this.accessProfile.postMessage;
          break;
        case 'announcement':
          hasTypePermission = this.accessProfile.postAnnouncement;
          break;
        case 'news':
          hasTypePermission = this.accessProfile.postNews;
          break;
        case 'ad':
          hasTypePermission = this.accessProfile.postAd;
          break;
        default:
          hasTypePermission = false;
      }
      let isVacancy = type === 'vacancy';
      return this.accessProfile.admin || this.accessProfile.allowSharePosts && allowedType && hasTypePermission && !isVacancy;
    }),
    commentIsFocusedObserver: Ember.observer('commentIsFocused', function () {
      if (this.commentIsFocused) {
        this.send('inputSanitize');
      }
    }),
    archive: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja arquivar esta publicação?');
        if (!value) {
          return;
        }
        Ember.set(this, 'post.archived', true);
        if (this.post.type === 'survey') {
          this.post.body.alternatives = this.post.body.alternatives.map(alternative => {
            if (alternative.users && alternative.users.length) {
              Ember.set(alternative, 'users', alternative.users.mapBy('id'));
            }
            return alternative;
          });
          Ember.set(this, 'post.body', JSON.stringify(this.post.body));
        }
        yield this.post.save();
        if (this.onArchivePost) {
          this.onArchivePost(this.post);
        }
        this.toast.success('Publicação arquivada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    findRelatedUsers: (0, _emberConcurrency.task)(function* () {
      try {
        const relatedUsersRaw = yield this.post.usersRelated.toArray();
        for (const relatedUser of relatedUsersRaw) {
          yield this.store.findRecord('user', relatedUser.id, {
            reload: true
          });
        }
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    unarchive: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Deseja desarquivar esta publicação?');
        if (!value) {
          return;
        }
        Ember.set(this, 'post.archived', false);
        if (this.post.type === 'survey') {
          this.post.body.alternatives = this.post.body.alternatives.map(alternative => {
            if (alternative.users && alternative.users.length) {
              Ember.set(alternative, 'users', alternative.users.mapBy('id'));
            }
            return alternative;
          });
          Ember.set(this, 'post.body', JSON.stringify(this.post.body));
        }
        yield this.post.save();
        if (this.onArchivePost) {
          this.onArchivePost(this.post);
        }
        this.toast.success('Publicação desarquivada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    togglePinPost: (0, _emberConcurrency.task)(function () {
      var _this = this;
      let priorityUntil = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return function* () {
        const message = _this.post.priority ? 'Deseja desfixar esta publicação?' : 'Deseja fixar esta publicação?';
        const responseToggle = _this.post.priority ? 'Publicação desfixada' : 'Publicação fixada';
        try {
          if (priorityUntil) {
            Ember.set(_this, 'post.priorityUntil', priorityUntil);
            Ember.set(_this, 'openTogglePinPostModal', false);
          } else {
            let {
              value
            } = yield _this.swal.confirm(message);
            if (!value) {
              return;
            }
          }
          _this.toggleProperty('post.priority');
          if (_this.post.type === 'survey') {
            // Remove referencia cíclica "users" das alternativas
            for (let i of _this.post.body.alternatives) {
              delete i.users;
            }
            // Para this.post.save() precisamos que o body seja uma string
            Ember.set(_this, 'post.body', JSON.stringify(_this.post.body));
          }
          yield _this.post.save();
          if (_this.post.type === 'survey') {
            // Logo após salvar, precisamos que o body continue sendo um object.
            // Esse parse é feito no didInsertElement do component ViewSurvey
            // (que não roda durante/após a task em que estamos)
            Ember.set(_this, 'post.body', JSON.parse(_this.post.body));
          }
          _this.post.reload();
          if (_this.onPinPost) {
            _this.onPinPost();
          }
          _this.toast.success(responseToggle);
        } catch (e) {
          _this.swal.catch(e);
        }
      }();
    }).drop(),
    confirmPresence: (0, _emberConcurrency.task)(function* () {
      try {
        let message = '';
        if (this.isConfirmed) {
          let {
            value
          } = yield this.swal.confirm('Confirmar cancelamento de sua participação neste evento?');
          if (!value) {
            return;
          }
          message = 'Sua inscrição neste evento foi cancelada';
        } else {
          message = 'Sua presença foi confirmada';
        }
        yield this.ajax.post(`posts/confirmPresence/${this.post.id}`);
        this.post.reload();
        this.toast.success(message);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    createComplaining: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.modal.open('dialogs/create-complaining');
        if (!result) {
          return;
        }
        let postComplaining = this.store.createRecord('post-complaining', {
          message: result.message,
          motive: result.option,
          post: this.post
        });
        Ember.set(this, 'post.complained', true);
        yield postComplaining.save();
        if (this.onArchivePost) {
          this.onArchivePost(this.post);
        }
        this.toast.success('A publicação foi reportada');
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    favorite: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          favorited
        } = yield this.ajax.post(`posts/${this.post.id}/favorite`);
        let message = 'removida dos';
        if (favorited) {
          message = 'adicionada aos';
        }
        this.post.reload();
        this.toast.success(`A publicação foi ${message} seus favoritos`);
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    postComment: (0, _emberConcurrency.task)(function* () {
      if (!this.newComment.length) {
        return;
      }
      try {
        let created = yield this.ajax.post('comments', {
          data: {
            comment: {
              mentions: this.commentMentions,
              post: this.post.id,
              text: this.newComment,
              user: this.session.user.id
            }
          }
        });
        Ember.setProperties(this, {
          commentMentions: null,
          newComment: '',
          newCommentRaw: ''
        });
        this.store.pushPayload(created);
        yield this.store.loadRecords('post', '/posts', {
          data: {
            id: this.post.id
          },
          host: 'core'
        });
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    remove: (0, _emberConcurrency.task)(function* () {
      try {
        let {
          value
        } = yield this.swal.confirm('Confirmar remoção desta publicação?');
        if (!value) {
          return;
        }
        this.swal.loading('Removendo publicação');
        let post = yield this.post.destroyRecord();
        if (this.onDeletePost) {
          this.onDeletePost(post);
        }
        this.toast.success('Publicação removida');
        this.post.reload();
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop(),
    getUserBranches: (0, _emberConcurrency.task)(function* () {
      try {
        const userBranches = yield this.permission.branches;
        Ember.set(this, 'branches', Ember.A(userBranches));
      } catch (e) {
        this.swal.catch(e);
      }
    }).drop().on('didInsertElement'),
    //Methods
    getPostBodyWithoutTags(post) {
      const regX = /(<([^>]+)>)/gi;
      if (post.body && typeof post.body !== 'object') {
        return post.body.replace(regX, '');
      }
      return '';
    },
    // Actions
    actions: {
      setShowBranchesViewModal() {
        Ember.set(this, 'showBranchesViewModal', !this.showBranchesViewModal);
      },
      focusOnComment() {
        let element = document.getElementById(`comment-on-post-${this.post.id}`);
        if (!element) {
          return;
        }
        element.focus();
      },
      toogleShowMorePostText() {
        const showMorePostText = !this.showMorePostText;
        Ember.set(this, 'showMorePostText', showMorePostText);
        let positionElement = window.pageYOffset;
        Ember.run.later(() => {
          // Busca a posição novamente quando for o ver menos
          if (!showMorePostText) {
            let element = document.getElementById(`show-more-${this.post.id}`);
            if (!element) {
              return;
            }
            element.scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center'
            });
          } else {
            window.scroll(0, positionElement);
          }
        }, 100);
      },
      shareSameBranche() {
        const branches = (Ember.get(this, 'post.branches') || []).mapBy('id');
        Ember.set(this, 'sharingBranches', branches);
        Ember.set(this, 'sharePostModal', true);
      },
      shareSameGroup() {
        const userGroup = Ember.get(this, 'post.userGroup.id') || null;
        Ember.set(this, 'sharingGroup', userGroup);
        Ember.set(this, 'sharePostModal', true);
      },
      onBranchesSelected(branches) {
        Ember.set(this, 'sharingBranches', branches);
        Ember.set(this, 'openBranchModal', false);
        (0, _emberConcurrency.timeout)(500).then(() => {
          Ember.set(this, 'sharePostModal', true);
        });
      },
      groupSelected(group) {
        Ember.set(this, 'sharingGroup', group);
        (0, _emberConcurrency.timeout)(500).then(() => {
          Ember.set(this, 'sharePostModal', true);
        });
      },
      onPostShared() {
        Ember.set(this, 'sharingBranches', null);
        Ember.set(this, 'sharingGroup', null);
        Ember.set(this, 'sharePostModal', false);
        if (this.onPinPost) {
          this.onPinPost();
        }
      },
      onPostShareWarning() {
        let msg = 'Essa publicação não pode ser compartilhada. Isso acontece porque o proprietário da publicação, alterou quem pode vê-la ou porque a publicação foi excluída.';
        this.swal.warning(msg).then(() => {
          Ember.set(this, 'sharePostModal', false);
        });
      },
      onCancelPostShare() {
        Ember.set(this, 'sharingBranches', null);
        Ember.set(this, 'sharingGroup', null);
        Ember.set(this, 'sharePostModal', false);
      },
      autoGrow() {
        const element = document.getElementById(`comment-on-post-${this.post.id}`);
        element.style.height = element.scrollHeight + "px";
      },
      inputSanitize() {
        const allowedIframeDomains = ['youtube.com', 'vimeo.com'];
        let options = {
          whiteList: {
            p: ["class"],
            span: ["class", "style"],
            strong: ["class", "style"],
            em: ["class", "style"],
            ul: [],
            ol: [],
            li: [],
            u: ["class", "style"],
            pre: ["class", "spellcheck", "style"],
            iframe: ["class", "src", "frameborder", "allowfullscreen", "showinfo", "width", "height"],
            img: ["src", "width", "height"],
            video: ["controlslist", "src", "controls", "width", "height", "webkit-playsinline", "playsinline", "x5-playsinline", "controlslist"],
            br: [],
            a: ["href", "rel", "target", "style", "class"],
            b: ["style", "class"],
            i: ["style", "class", "aria-hidden"]
          },
          stripIgnoreTagBody: ["script"],
          // Remove a tag script
          onTag: (tag, html, options) => {
            if (tag === 'iframe') {
              const srcMatch = html.match(/src="([^"]+)"/);
              if (srcMatch) {
                const src = srcMatch[1];
                const url = new URL(src);
                if (allowedIframeDomains.includes(url.hostname)) {
                  return ''; // Remove a tag iframe se o domínio não for permitido
                }
              }
            }
          }
        };

        const textSanitized = (0, _xss.default)(this.newCommentRaw, options);
        Ember.set(this, 'newComment', textSanitized);
      }
    },
    // LifeCycles
    init() {
      this._super(...arguments);
      const postBodyWithoutTags = this.getPostBodyWithoutTags(this.post);
      this.showMoreIsNeeded = postBodyWithoutTags.length > POST_TEXT_MAX_SIZE && !this.isDetail;
      this.showMorePostText = Boolean(this.isDetail);
      this.findRelatedUsers.perform();
    }
  });
  _exports.default = _default;
});