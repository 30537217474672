define("portal-talentrh/helpers/time-difference", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function timeDifference(_ref, _ref2) {
    let [deferDate] = _ref;
    let {
      minutes = 30
    } = _ref2;
    if (!deferDate) {
      return 'Data inválida';
    }
    const deferredMoment = (0, _moment.default)(deferDate).subtract(minutes, 'minutes');
    return deferredMoment.format('DD/MM/YYYY HH:mm:ss');
  });
  _exports.default = _default;
});